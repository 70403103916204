<template>
  <div>
    <div v-if="serviceStatus === false">
      The backend is temporarily offline. 😢
    </div>
    <div v-else-if="serviceStatus === true">
      <div>Version: #version#</div>
      <div>Release date: #releaseDate#</div>
    </div>
    <div v-else>
      LOADING
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Global from "@/store/global.js";
export default {
  data() {
    return {
      serviceStatus: null
    };
  },
  mounted() {
    var vm = this;
    axios
      .get(Global.restServer.url + "/about")
      .then(function(response) {
        vm.serviceStatus =
          response.data.Version !== undefined && response.data.Version !== null;
      })
      .catch(function(error) {
        vm.serviceStatus = false;
        console.log(error);
      });
    $("#loading").hide();
  }
};
</script>
