<template>
    <div id="illness-review" style="min-height: 75vh;">
      <div>
        <div class="row blue-bg mb-5 text-center p-4">
            <div class="col my-3">
                <div class="text-center">
                    <img src="images/thankyou-va.png" alt="" style="height: 150px" />
                </div>
                <div class="mt-3 ml-3 mr-3">
                    <h4>{{ $t("debitThankyouTitle") }}</h4>
                    <p class="mbot-0">{{ $t("debitThankyouMsg1") }}</p>
                    <p>{{ $t("debitThankyouMsg2") }}</p>
                </div>
                
            </div>
        </div>

        <button
            class="btn btn-gohome btn-block"
            data-selenium-name="floating-buynow"
            @click="gobackhome()"
        >
            <h>{{ $t("buttonDebitThankyou") }}</h>
        </button>

          <div style="padding-top:10vh">&nbsp;</div>
      </div>
        <div class="modal modal-v1 fade" id="debiterror-modal" tabindex="-1">
          <div class="modal-dialog" style="max-width:400px !important;">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  X
                </button>
                <div class="text-center mt-3">
                  <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/sorry4commercial.png" class="lazy" style="width:120px;"/>
                </div>
              </div>
              <div class="modal-body">
                <h6 class="text-black">{{ $t("debit_card_error.title") }}</h6>
                <div class="text-black">
                <span>{{ $t("debit_card_error.message") }}
                <a
                    href="tel:02150890822"
                    role="button"
                    style="color: #ff5011;"
                    data-selenium-name="footer-roojai-number"
                    data-selenium-value="021 5089 0822"
                  >
                    Contact Center
                  </a>
                  {{ $t("debit_card_error.message2") }}</span>
                </div>
              </div>
              <div class="modal-footer pb-2 px-2">
                <button
                  type="button"
                  class="btn btn-confirm w-100"
                  data-dismiss="modal"
                  @click="checkStatusPayment()"
                >
                  {{ $t("debit_card_error.button_confirm") }}
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";
import axios from "axios";

export default {
  name: "thankyou",
  mixins: [Mixin],
  data() {
    return {
      Global,
      isSuccessPayment : false
    };
  },
  created() {},
  mounted() {
    window.scrollTo(0, 0);
    // var vm = this,
    //   nIntervId,
    //   countInterval = 0,

    // nIntervId = setInterval(async function() {
    //   countInterval++;
    //   if (
    //     (Global.restServer.token !== "" && Global.roojaiService.token !== "" && Global.odataService.token !== "" )
    //   ) {
    //     // clearInterval(nIntervId);
    //     if(vm.$route.query.opportunityNumber !== undefined){
    //       await vm.checkStatusPayment(vm.$route.query.opportunityNumber);
    //             if ( (!vm.isSuccessPayment) && (countInterval < 6)) {
    //               await vm.checkStatusPayment(vm.$route.query.opportunityNumber);
    //             }else if(vm.isSuccessPayment){
    //               clearInterval(nIntervId);
    //               vm.loading("hide");
    //             }
    //             if (countInterval > 6) {
    //               clearInterval(nIntervId);
    //               vm.loading("hide");
    //               vm.$router.push({name:"debit-failure"});
    //             }
    //     }
    //   }
    // }, 5000);
    this.loading('hide')
  },
  methods: {
    gobackhome: function() {
      var vm = this;

      vm.loading("show", 2, function() {
        window.location.href = "https://myaccount.roojai.co.id/#/";
      });
    },
    checkStatusPayment: async function(opptyNumber) {
      var vm = this;
      try {
        var response = await axios.get(
          Global.odataService.url + "/payment/ayc/debitstatus?opportunityNumber=" + opptyNumber,
          {
            headers: { Authorization: "Bearer " + Global.odataService.token },
          }
        );
        if (response.data.status == "success") {
          vm.isSuccessPayment = true
        }else {
          vm.isSuccessPayment = false
        }
      } catch (error) {
        vm.isSuccessPayment = false
        vm.loading("hide")
        vm.errorModal(vm.$t("debitCardError"),"debit card");
      }
    }
  }
};
</script>
<style scoped>
.btn-gohome{
    width: 500px;
    margin: auto;
    margin-top: 2.5em;
    border-radius: 0.5em;
    margin-bottom: 1.5em;
    color: #fff;
    background-color: #ff5011;
}
.mbot-0{
  margin-bottom: 0px !important;
}
@media (max-width: 500px) {
  .btn-gohome{
    width: 90%;
  }
}
</style>
<i18n>
{
  "en": {
    debitThankyouTitle : "Success! ",
    debitThankyouMsg1 : "Your debit card details has been successfully updated.",
    debitThankyouMsg2 : "You can now make payments using the registered debit card.",
    debitCardError:"Oops, an error occurred, and we cannot proceed debit card payment. Please try again later",
    buttonDebitThankyou : "Back to MyAccount",
    "debit_card_error" : {
      "title" : "Seems like we’re facing an issue",
      "message" : "We couldn’t get you to your destination page. Try again in a few minutes or contact our ",
      "message2" : "",
      "button_cancel" : "Change Payment Method",
      "button_confirm" : "Try Again",
    }
  },
  "id": {
    debitThankyouTitle : "Berhasil! ",
    debitThankyouMsg1 : "Detail kartu debitmu berhasil diperbarui.",
    debitThankyouMsg2 : "Kamu sudah bisa melakukan pembayaran menggunakan kartu debit yang telah terdaftar.",
    debitCardError:"Ups, terjadi kesalahan dan kami tidak dapat memproses pembayaran debit card. Silakan coba lagi nanti",
    buttonDebitThankyou : "Kembali ke MyAccount",
    "debit_card_error" : {
      "title" : "Halaman yang kamu tuju bermasalah",
      "message" : "Kami tidak dapat menghubungkanmu ke halaman yang dituju. Silakan coba lagi dalam beberapa menit atau hubungi ",
      "message2" : "kami.",
      "button_cancel" : "Ubah Metode Pembayaran",
      "button_confirm" : "Coba Lagi",
    },
  },
}
</i18n>