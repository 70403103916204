<style>
    .productPage main {
        margin-top: 0px;
    }
    .productPage {
        color: #43464c !important;
        font-size: 16px;
    }
    .productPage .h1, .productPage .h2, .productPage .h3, .productPage .h4, .productPage .h5, .productPage .h6, .productPage h1, .productPage h2, .productPage h3, .productPage h4, .productPage h5, .productPage h6 {
        line-height: 1.35;
        color: #003b71;
    }
    .productPage .h1, .productPage h1 {
        font-size: 2em;
    }
    .productPage .h5, .productPage h5 {
        font-size: 1.2em;
    }
    .productPage b, .productPage strong {
        font-weight: 500;
    }
    .productPage ol>li, .productPage ul>li {
        margin-bottom: 0.5rem;
    }
    .productPage .font-weight-normal {
        font-weight: normal !important;
    }
    .productPage .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .productPage .navbar {
        padding: 15px 5px;
        box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%);
        background-color: #fff;
    }
    .productPage .navbar .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .productPage .navbar .container .logo{
        margin:0 auto;
    }
    .productPage .user-login {
        margin-right:5px;
    }
    .productPage .switch-lang .dropdown-menu {
        min-width: auto;
    }
    .productPage .switch-lang .dropdown-toggle img {
        margin-right: 15px;
    }
    .productPage .switch-lang .btn.disabled, .productPage .switch-lang .btn:disabled {
        opacity: .35;
    }
    .productPage .roojai-logo {
        height: 50px;
    }
    .productPage .site-branding {
        min-width: 60px;
        min-height: 60px;
        float: none;
        height: auto;
        padding: 0;
    }
    .productPage .navbar-brand .roojai-logo {
        height: 60px;
        width: 80px;
        display: block;
        background-image: url(/images/roojai-logo.svg);
        background-repeat: no-repeat;
        background-size: contain;
    }
    .productPage .agent-login {
        padding: 10px;
        background-color: #eceff1;
        text-align: right;
    }
    .productPage .header-caption {
        margin-bottom:2.5rem;
    }
    .productPage .header-description {        
        font-size: 1.25em;
        margin-bottom: 2.5rem;
        font-weight: 400;
    }
    .productPage .product-links {
        margin-bottom: 3rem;
    }
    .productPage .text-white {
        color:#fff !important;
    }
    .productPage .text-primary {
        color:#ff5011 !important;
    }
    .productPage .text-secondary {
        color: #003b71 !important;
    }
    .productPage .card {
        background: #fff;
        border-radius: 10px;
        padding: 10px 0px;
        margin:10px;
    }
    .productPage a.hero-link .card p {
        color: #003b71 !important;
        transition: color .15s ease-in-out;
        font-size: 13px;
    }
    .productPage a.hero-link:hover .card{ 
        box-shadow: 0 0.15rem 1.75rem 0 rgb(255 255 255 / 15%);
    }
    .productPage a.hero-link:hover .card p {
        color: #ff5011 !important;
    }
    .productPage .mb-0 {
        margin-bottom:0px !important;
    }
    .productPage .mx-auto {
        margin-left: auto!important;
        margin-right: auto!important;
    }
    .productPage .mt-2, .productPage .my-2 {
        margin-top: .5rem!important;
    }
    .productPage .section{
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .productPage .my-50{
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .productPage .my-75{
        margin-top: 75px;
        margin-bottom: 75px;
    }
    .productPage .my-100{
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .productPage .py-50{
        padding-top: 30px;
        padding-bottom: 30px;
    } 
    .productPage .py-75{
        padding-top: 75px;
        padding-bottom: 75px;
    }
    .productPage .py-100{
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .productPage #product-link {
        display: flex;
        justify-content: center;
    }
    .productPage .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;        
    }
    .productPage a.hero-link, .productPage .section-usp .card a {
        color: #003b71;
        text-decoration: none;
        background-color: transparent;
        transition: color .15s ease-in-out;
        cursor: pointer;
    }
    .productPage a.hero-link:hover, .productPage .section-usp .card:hover a {
        color: #ff5011;
    }
    .productPage #product-link .hero-link .card-hero-icon {
        padding: 0;
        height: 160px;
        width: 110px;
    }
    .productPage #usp-info .media img {
        width: 80px;
        height: 80px;
    }
    .productPage #usp-info .media {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .productPage #usp-info .media .media-body h2 {
        font-size: 22px;
    }
    .productPage #usp-info .media .media-body p {
        font-size: 16px;
        color: #43464c;
    }
    .productPage .extend-plans .card {
        border-radius: 0.75rem !important;
        margin-bottom: 1.5rem;
        padding: 0;
    }
    .productPage .extend-plans .card .card-header {
        border-top-right-radius: .75rem;
        border-top-left-radius: 0.75rem;
    }
    .productPage .bg-secondary {
        background-color: #003b71!important;
    }
    .productPage .coverage-icon-box {
        width: 80px;
        height: 80px;
        margin: 0 auto 1rem auto;
        background-repeat: no-repeat;
        border: 1px solid #ced8e0;
        border-radius: .5rem;
        background-color: #eceff1;
    }
    .productPage .coverage-icon-1 {
        background-position: 0px 0px;
    }
    .productPage .coverage-icon-2 {
        background-position: -80px 0px;
    }
    .productPage .coverage-icon-3 {
        background-position: -161px 0px;
    }
    .productPage .coverage-icon-4 {
        background-position: -240px 0px;
    }
    .productPage .coverage-icon-5 {
        background-position: -320px 0px;
    }
    .productPage .coverage-icon-6 {
        background-position: -400px 0px;
    }
    .productPage .coverage-icon-7 {
        background-position: -480px 0px;
    }
    .productPage .coverage-icon-8 {
        background-position: -560px 0px;
    }
    .productPage .coverage-icon-9 {
        background-position: -640px 0px;
    }
    .productPage .coverage-icon-10 {
        background-position: -720px 0px;
    }
    .productPage .coverage-icon-11 {
        background-position: -800px 0px;
    }
    .productPage .coverage-icon-12 {
        background-position: -880px 0px;
    }
    .productPage .coverage-icon-13 {
        background-position: -960px 0px;
    }
    .productPage .card-hospital {
        min-height: 400px;
        border-radius: 0.75em;
    }
    .cancer-3-item p {
        font-size: 1.25em;
        font-weight: 500;
    }
    .productPage .col-content-center {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .productPage .col-content-bottom {
        display: flex;
        align-items: flex-end;
    }
    .productPage .table thead tr th {
        color:#fff;
        font-weight: 500;
        font-size: 14px;
        white-space: normal !important;
    }
    .productPage .table tbody tr td {
        padding:.5em;
        font-size: 13px;
        min-width: 80px;
    }
    .productPage .table-cancer-plan-cn thead tr th{
        font-size: 13px;
    }
    .productPage .table-cancer-plan-cn thead tr th:not(:first-child), .table-cancer-plan-cn tbody tr td:not(:first-child){
        text-align: center;
    }
    .productPage .card-all-products {
        padding:30px 10px !important;
        margin: 0;
    }
    .productPage footer {
        margin-top: 30px;
        padding: 30px 0 15px 0;
        background-color: #eceff1;
    }
    .productPage footer .row>[class*=col-] {
        margin-bottom: 1em;
    }
    footer .footer-company p {
        font-size: 14px;
    }
    .productPage footer .footer-phone .btn.btn-link {
        font-size: 2em;
        background-color: #fff;
        border: 1px solid #cfd8dc;
        border-radius: 50px;
        padding: 5px 10px;
        color: #ff5011;
        text-decoration: none;
    }
    .productPage footer .footer-phone .btn.btn-link .glyphicons {
        background-color: #cfd8dc;
        padding: 10px;
        border-radius: 50%;
        font-size: 16px;
        color: #ff5011;
        background-image: linear-gradient(to top,#c4c5c7 0,#dcdddf 52%,#ebebeb 100%);
        -webkit-box-shadow: inset 0 1px 3px 1px rgb(0 0 0 / 10%);
        box-shadow: 0 1px 3px 1px rgb(0 0 0 / 10%);
    }
    .productPage footer .footer-standard-logo img {
        max-width: 90px;
    }

    #co-branding-affiliate .promotions {
        margin: 3rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #co-branding-affiliate .promotions img {
        margin: 1rem;
    }

    @media (min-width: 320px) and (max-width: 767.98px){
        .productPage #product-link .hero-link .card {
            min-height: 155px;
        }
        .productPage #product-link.product-link-th .hero-link .card {
            min-height: 135px;
        }
        .productPage #product-link.product-link-zh .hero-link .card {
            min-height: 135px;
        }
    }

    @media (max-width: 448px) {
        .productPage .table-cancer-plan tbody tr td:first-child, .productPage .table-cancer-plan-cn tbody tr td:first-child {
            min-width: 130px;
            white-space: normal;
        }
    }

    @media (min-width: 768px) {
        .productPage .site-branding {
            min-width: 80px;
            min-height: 80px;
        }
        .productPage .navbar-brand .roojai-logo {
            height: 77px;
            width: 100px;
        }
        .productPage .row-rtl {
            flex-direction: row-reverse;
        }
        .productPage .text-md-right {
            text-align: right;
        }
        .productPage .product-links{
            max-width: 500px;
            margin: auto;
        }
        .productPage main {
            margin-top: 0px;
        }
        .productPage .roojai-logo {
            height: 77px;
        }
        .productPage .h1, .productPage h1 {
            font-size: 2.25em;
        }
        .productPage .h2, .productPage h2 {
            font-size: 2.5em;
        }
        .productPage .h3, .productPage h3 {
            font-size: 1.5em;
            line-height: 1.5em;
        }
        .productPage .h4, .productPage h4 {
            font-size: 1.75rem;
        }
        .productPage .hero-area {
            height: auto;
            padding:50px 0px !important;
            background-image: url(/images/product/bg-home-hero.jpg) !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
        .productPage .card-all-products {
            height: auto;
            padding:50px !important;
            background-image: url(/images/product/bg-home-hero.jpg) !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover; 
        }
        .productPage .card-hospital {
            background-image: url(/images/product/hospital-bg-hero.jpg) !important;
            background-position: center;
            background-size: cover;
        }
        .productPage .table tbody tr td {
            font-size: 14px;
            width: auto;
            padding: 1rem;
        }
        .productPage #product-link .hero-link .card {
            min-height: 135px;
        }

        #co-branding-affiliate.hero-area {
            height: auto;
            padding:50px 0px !important;
            background-image: url(/images/bg-co-branding.jpg) !important;
            background-repeat: no-repeat;
            background-position: right center;
            background-size: cover;
        }
        #co-branding-affiliate .promotions {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    @media (min-width: 992px) {
        .productPage .h1, .productPage h1 {
            font-size: 3em;
        }
        .productPage .h2, .productPage h2 {
            font-size: 2.75em;
        }
        .productPage .h3, .productPage h3 {
            font-size: 2em;
            line-height: 1.5em;
        }
        .productPage .h4, .productPage h4{
            font-size: 2rem;
        }
        .productPage .li, .productPage li{
            font-size: 1.125rem;
        }
        .productPage .p, .productPage p{
            margin-bottom: 0px;
            font-size: 1.125rem;
        }
        .productPage .section {
            margin-top: 100px;
            margin-bottom: 100px;
        }
        .productPage .header-area, .productPage .header-area .row {
            min-height: 500px;
        }
        /* .productPage #product-link .hero-link .card-hero-icon {
           min-height: 132px;
        } */
        .productPage #product-link .hero-link .card p {
            font-size: 13px;
        }
        .productPage .section-usp .card {
            width: 210px;
        }
        .productPage #usp-info .media img {
            width: 150px;
            height: 150px;
        }
        .productPage #usp-info .media .media-body h2 {
            font-size: 24px;
        }
        .productPage #car-insure-hero img.img-fluid {
            float: right;
        }
        .productPage .extend-plans .card {
            min-height: 413px;
        }
        .productPage .cancer-3-item p {
            font-size: 1.5em;
        }
    }

    @media (min-width: 1200px) {
        .productPage .container {
            margin-top: 0;
        }
        .productPage .product-links{
            max-width: 1024px;
            margin: 3rem auto;
        }
        .productPage .hero-area {
            /* height: 720px; */
            padding:50px 0px !important;
            background-image: url(/images/product/bg-home-hero.jpg) !important;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
        #co-branding-affiliate.hero-area {
            background-position: center;
        }
    }

    .btn-orange {
        border-radius: 8px;
        -moz-border-radius: 8px;
        -webkit-border-radius: 8px;
        color: #fff !important;
        background-color: #ff5011;
    }
    .btn-orange:focus, .btn-orange:hover {
        background-color: #fd6027;
        color: #fff;
    }
    
    .btn .caret {
        margin-left: 0;
    }
    .caret {
        position: absolute;
        right: 10px;
        top: 18px;
    }
    .caret {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid\9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    /* For Co-branding */
    .productPage .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .productPage .partner-logo {
        display: contents;
    }
    .productPage .partner-logo img {
        max-height: 40px;
        width: auto;
    }
    .productPage .partner-logo svg {
        margin: 1rem;
    }
    .productPage .central-terms li {
        line-height: 1.5;
        margin-bottom: 1rem;
    }
    .productPage .partner-logo img {
        max-height: 60px;
        width: auto;
    }
</style>
<template>
    <div class="page-content productPage">
        <nav class="navbar bg-white navbar-fixed-top">
            <div class="container">
                <div class="logo text-center">
                    <!-- <a href="https://www.roojai.com/" rel="home" itemprop="url" class="navbar-brand roojai-logo me-0 py-0"></a> -->
                    <a href="#">
                        <img src="images/roojai-logo-id.png" alt="Roojai.co.id" class="roojai-logo">  
                    </a>
                    <!-- <img :src=intermediaryLogoUrl alt="Intermediary logo" v-if="intermediaryLogoUrl" class="roojai-logo">  -->
                    <div class="partner-logo" v-if="isCoBrandingAffiliate">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="#ccc" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        <!-- <img :src="coBrandingDisplay.logo" class="img-responsive" width="92" height="60" alt="Central Group" /> -->
                    </div>
                </div>
                
                <div class="dropdown switch-lang" data-selenium-name="selected-lang" :data-selenium-value="Global.quote.prefLang">
                    <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img v-show="Global.quote.prefLang=='id'" class="lazy img-fluid" src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/id.png" alt="Language id">
                        <img v-show="Global.quote.prefLang=='en'" class="lazy img-fluid" src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/en.png" alt="Language en">
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <li class="switch-lang-th">
                            <a class="dropdown-item" data-selenium-name="language" data-selenium-value="id" @click="Global.quote.prefLang='id'" :class="{'type-active' : Global.quote.prefLang == 'id'}" role="button">
                                <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/id.png" alt="Indo" class="lazy img-fluid">
                            </a>
                        </li>
                        <li class="switch-lang-en">
                            <a class="dropdown-item" data-selenium-name="language" data-selenium-value="en" @click="Global.quote.prefLang='en'" :class="{'type-active' : Global.quote.prefLang == 'en'}" role="button">
                                <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/en.png" alt="English" class="lazy img-fluid">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <main>
            <div class="agent-login">
                <div class="container" style="margin-top:0px">
                    <span class="user-login">{{hideDomain(Global.brokerInfo.intermediaryReptEmail)}}</span>
                </div>
            </div>

            <!-- All Product -->
            <div id="product-default" v-if="!isCoBrandingAffiliate && displayAllProducts">
                <div class="hero-area" style="background-image: linear-gradient(120deg, #0f5fa9 0, #003b71 70%);padding:30px 0px;" v-if="!isCoBrandingAffiliate && displayAllProducts">                
                    <div class="container text-center" style="max-width: 1000px;"><!-- <h1 class="header-caption text-white"><span class="text-primary">Did you know you can save up to 30%</span><br>with Roojai online insurance?</h1> -->
                        <h1 class="header-caption text-white">
                            <span class="text-primary">{{$t("headerCaption.firstline")}}</span>
                            <br>
                            {{$t("headerCaption.secondline")}}  
                        </h1>
                        <div id="product-link" class="row justify-content-center my-5 mx-0" :class="classLocale">
                            <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="( Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('motorcar'))">
                                <a class="hero-link" data-selenium-name="car-btn" v-on:click="selectProduct('carInsurance')" rel="follow" title="Car insurance">
                                    <div class="card card-hero-icon card-hero-car mb-3 mx-auto">
                                        <div class="card-body py-3 px-3 text-center">
                                            <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/car-icon.svg" alt="Car insurance">
                                            <p class="mb-0">{{$t("motorCar")}}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="(Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('cardio'))">
                                <a class="hero-link" data-selenium-name="extended-warranty-btn" v-on:click="selectProduct('heartdiseases')" rel="follow" title="Heart Diseases Insurance">
                                    <div class="card card-hero-icon card-hero-extended mb-3 mx-auto">
                                        <div class="card-body py-3 px-1 text-center">
                                            <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/heart-icon.svg" alt="Heart Diseases Insurance"> 
                                            <p class="mb-0">{{$t("heartdiseases")}}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="(Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('dengue'))">
                                <a class="hero-link" data-selenium-name="motorbike-btn" v-on:click="selectProduct('mosquito')" rel="follow" title="Mosquito Borne Insurance">
                                    <div class="card card-hero-icon card-hero-moto mb-3 mx-auto">
                                        <div class="card-body py-3 px-2 text-center">
                                            <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/mosquito-icon.svg" alt="Mosquito Borne Insurance">
                                            <p class="mb-0" v-if="Global.quote.prefLang !== 'en'">{{$t("motorBike")}}</p>
                                            <p class="mb-0" v-else>
                                                <i18n tag="span" path="motorBikeHtml">
                                                    <span>{{$t('motorBikeBike')}}<br></span>
                                                    <span>{{$t('motorBikeInsurance')}}</span>
                                                </i18n>
                                            </p>
                                            <!-- <p class="mb-0"><i18n tag="span" path="motorBikeHtml"><span>{{$t('motorBikeBike')}}<br></span><span>{{$t('motorBikeInsurance')}}</span></i18n></p> -->
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="(Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('pa'))">
                                <a class="hero-link" data-selenium-name="personal-accident-btn" v-on:click="selectProduct('PAInsurance')" rel="follow" title="Personal accident insurance">
                                    <div class="card card-hero-icon card-hero-pa mb-3 mx-auto">
                                        <div class="card-body py-3 px-2 text-center">
                                            <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/pa-icon.svg" alt="Personal accident insurance">
                                            <p class="mb-0">
                                                <i18n tag="span" path="paHtml">
                                                    <!-- <span>{{$t('paInsurance')}}</span> -->
                                                    <span>{{$t('paPersonal')}}<br v-if="Global.quote.prefLang !== 'id'"></span>
                                                </i18n>
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="(Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('cancer'))">
                                <a class="hero-link" data-selenium-name="cancer-btn" v-on:click="selectProduct('cancerInsurance')" rel="follow" title="Cancer insurance">
                                    <div class="card card-hero-icon card-hero-cancer mb-3 mx-auto">
                                        <div class="card-body py-3 px-2 text-center">
                                            <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/cancer-icon.svg" alt="Cancer insurance">
                                            <p class="mb-0">{{$t("cancerInsurance")}}<br>&nbsp;</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="( Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('ci'))">
                                <a class="hero-link" data-selenium-name="critical-illness-btn" v-on:click="selectProduct('CIInsurance')" rel="follow" title="Critical illness insurance">
                                    <div class="card card-hero-icon card-hero-ci mb-3 mx-auto">
                                        <div class="card-body py-3 px-2 text-center">
                                            <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/ci-icon.svg" alt="Critical illness insurance">
                                            <p class="mb-0">
                                                <i18n tag="span" path="ciHtml">
                                                    <span>{{$t('ciInsurance.line1')}}</span>
                                                    <span><br>{{$t('ciInsurance.line2')}}</span>
                                                </i18n>
                                            </p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <p class="header-description text-white" >{{$t("headerDesc")}}</p>
                    </div>
                </div>
            </div>
            
            <!-- MotorCar -->
            <div id="product-car-insurance" v-if="!isCoBrandingAffiliate && (Global.productPageDisplay=='carInsurance')">
                <div id="car-insure-hero" class="header-area" style="background-image: linear-gradient(180deg, #0f5fa9 0, #003b71 45%); background-color: #0f5fa9;">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-md-8 py-5 col-content-center">
                                <h2 class="text-white">{{$t("car.header01")}}</h2>
                                <p class="text-white font-weight-normal">{{$t("car.header02")}}</p>
                                <a v-on:click="getProductQuote('car')" data-selenium-name="get-quote-car" rel="follow" title="Voluntary Car Insurance" class="btn btn-orange btn-lg px-5 mt-5">{{$t("car.headerButton")}}</a>
                            </div>
                            <div class="col-xs-12 col-md-4 col-content-bottom">
                                <img width="340" height="256" class="img-responsive" src="/images/product/car-insure-cheap.png" alt="Voluntary Car Insurance in Thailand">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Heart Diseases -->
            <div id="product-extended-warranty-insurance" v-if="!isCoBrandingAffiliate && (Global.productPageDisplay=='heartdiseases')">
                <div id="extended-insure-hero" class="header-area" style="background-image: linear-gradient(180deg, #0f5fa9 0, #003b71 45%); background-color: #0f5fa9;">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-md-8 py-5 col-content-center">
                                <h2 class="text-white">{{$t("hd.header01")}}</h2>
                                <p class="text-white font-weight-normal">{{$t("hd.header02")}}</p>
                                <a v-on:click="getProductQuote('heartdiseases')" data-selenium-name="get-quote-ew" rel="follow" title="Heart Diseases Insurance" class="btn btn-orange btn-lg px-5 mt-5">{{$t("hd.headerButton")}}</a>
                            </div>
                            <div class="col-xs-12 col-md-4 col-content-bottom">
                                <img width="340" height="256" class="img-responsive" src="/images/product/hero-lg.png" alt="Heart Diseases Insurance in Indonesia">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-area py-50 pb-0">
                    <div class="container">
                        <div class="row my-3">
                            <div class="col-xs-12 col-sm-4 col-md-5">
                                <img width="280" height="373" class="img-responsive" src="images/product/pa-group.jpg" alt="Heart Diseases Insurance in Indonesia">
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-7 col-content-center">
                                <h4>{{$t("hd.section01.header01")}}</h4>
                                <ul>
                                    <li>{{$t("hd.section01.p01")}}</li>
                                    <li>{{$t("hd.section01.p02")}}</li>
                                    <li>{{$t("hd.section01.p03")}}</li>
                                    <li>{{$t("hd.section01.p04")}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <!-- Mosquito-borne -->
            <div id="product-motorbike-insurance" v-if="!isCoBrandingAffiliate && (Global.productPageDisplay=='mosquito')">
                <div id="motorbike-insure-hero" class="header-area" style="background-image: linear-gradient(180deg, #0f5fa9 0, #003b71 45%); background-color: #0f5fa9;">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-md-8 py-5 col-content-center">
                                <h2 class="text-white">{{$t("mb.header01")}}</h2>
                                <p class="lead text-white">{{$t("mb.header02")}}</p>
                                <a v-on:click="getProductQuote('mosquito')" data-selenium-name="get-quote-motorbike" rel="follow" title="Mosquito Borne Insurance in Indonesia" class="btn btn-orange btn-lg px-5 mt-5">{{$t("mb.headerButton")}}</a>
                            </div>
                            <div class="col-xs-12 col-md-4 col-content-bottom">
                                <img width="340" height="256" class="img-responsive" src="/images/product/hero-lg.png" alt="Mosquito Borne Insurance in Indonesia">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-area py-50">
                    <div class="container">
                        <div class="row my-3">
                            <div class="col-xs-12 col-sm-4 col-md-5">
                                <img width="280" height="373" class="img-responsive" src="images/product/pa-group.jpg" alt="Mosquito Borne Insurance in Indonesia">
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-7 col-content-center">
                                <h4>{{$t("mb.section01.h4")}}</h4>
                                <ul>
                                    <li>{{$t("mb.section01.p01")}}</li>
                                    <li>{{$t("mb.section01.p02")}}</li>
                                    <li>{{$t("mb.section01.p03")}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="care-card py-75" style="background-image: linear-gradient(180deg, #f1f2f6 0, #dfe4ea 100%);">
                        <div class="container">
                            <div class="row">
                                <div class="col-xs-12 col-sm-3 col-sm-offset-1 col-md-2 col-md-offset-2">
                                    <img width="111" height="177" class="img-responsive mx-auto" src="images/product/ecare-card.png" alt="Digital Roojai E-Care Card">
                                </div>
                                <div class="col-xs-12 col-sm-8 col-md-6">
                                    <div class="lead"><br>{{$t("mb.section02.h301")}}<br v-if="Global.quote.prefLang !== 'zh'">{{$t("mb.section02.h302")}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hospitals container">
                        <div class="row card-hospital align-items-center my-75 mb-5" style="background-image: linear-gradient(120deg, #0f5fa9 0, #003b71 45%);">
                            <div class="col-xs-12 col-sm-10 col-md-8 col-content-center p-5">
                                <h2 class="text-white">{{$t("mb.section03.h2")}}</h2>
                                <p class="lead text-white">{{$t("mb.section03.p")}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Cancer -->
            <div id="product-cancer-insurance" v-if="!isCoBrandingAffiliate && (Global.productPageDisplay=='cancerInsurance')">
                <div class="cancer-insure-hero header-area" style="background-image: linear-gradient(180deg, #0f5fa9 0, #003b71 45%); background-color: #0f5fa9;" >
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-md-8 py-50 col-content-center">
                                <h2 class="text-white">{{$t("cancer.header")}}</h2>
                                <p class="lead text-primary">{{$t("cancer.subHeader")}}</p>
                                <a v-on:click="getProductQuote('health')" data-selenium-name="get-quote-health" rel="follow" title="Personal Accident Insurance" class="btn btn-orange btn-lg px-5 mt-5">{{$t("cancer.headerButton")}}</a>
                            </div>
                            <div class="col-xs-12 col-md-4 col-content-bottom">
                                <img width="340" height="256" class="img-responsive" src="images/product/cancer-hero-2.png" alt="Online Personal Accident Insurance in Thailand">
                            </div>
                        </div>
                    </div>                
                </div>
                <div class="info-area py-50">
                    <div class="container">
                        <!-- <div class="text-center mb-5">
                            <h2>{{$t("cancer.section01.h2")}}</h2>
                            <p class="lead">{{$t("cancer.section01.p01")}}</p>
                            <p class="lead">{{$t("cancer.section01.p02")}}</p>
                            <div class="my-100"></div>
                            <h3>{{$t("cancer.section01.h03")}}</h3>
                            <p class="lead text-primary">{{$t("cancer.section01.p03")}}</p>
                            <p class="lead">{{$t("cancer.section01.p04")}}</p>
                            <img v-if="Global.quote.prefLang=='id'" width="600" height="300" class="img-responsive mx-auto my-5" src="images/product/cancer-cover-5-year-th.png" alt="fixed premium for 5 years">                            
                            <img v-if="Global.quote.prefLang=='en'" width="600" height="300" class="img-responsive mx-auto my-5" src="images/product/cancer-cover-5-year-en.png" alt="fixed premium for 5 years">                            
                            <img v-if="Global.quote.prefLang=='zh'" width="600" height="300" class="img-responsive mx-auto my-5" src="images/product/cancer-cover-5-year-cn.png" alt="fixed premium for 5 years">                            
                        </div> -->
                        <div class="row">
                            <div class="col-xs-12 col-sm-4 col-md-5">
                                <img width="280" height="373" class="img-responsive mx-auto" src="images/product/pa-group.jpg" alt="low cost cancer insurance premium with Roojai">
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-7 col-content-center">
                                <h4>{{$t("cancer.section01.h2")}}</h4>
                                <ol>
                                    <li>{{$t("cancer.section01.p01")}}</li>
                                    <li>{{$t("cancer.section01.p02")}}</li>
                                    <li>{{$t("cancer.section01.p03")}}</li>
                                    <li>{{$t("cancer.section01.p04")}}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="row my-3 row-rtl">
                            <div class="col-xs-12 col-sm-4 col-md-5">
                                <img width="280" height="373" class="img-responsive mx-auto" src="images/product/cancer-renew.png" alt="buy your cancer insurance policy online with Roojai">
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-7 col-content-center">
                                <h4>{{$t("cancer.section02.h3")}}</h4>
                                <ul>
                                    <li>{{$t("cancer.section02.col01")}}</li>
                                    <li>{{$t("cancer.section02.col02")}}</li>
                                    <li>{{$t("cancer.section02.col03")}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-sm-4 col-md-5">
                                <img width="280" height="373" class="img-responsive mx-auto" src="images/product/cancer-group.png" alt="low cost cancer insurance premium with Roojai">
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-7 col-content-center">
                                <h4>{{$t("cancer.section03.h4")}}</h4>
                                <ol>
                                    <li>{{$t("cancer.section03.lo01")}}</li>
                                    <li>{{$t("cancer.section03.lo02")}}</li>
                                    <li>{{$t("cancer.section03.lo03")}}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Personal Accident -->
            <div id="product-personal-accident-insurance" v-if="!isCoBrandingAffiliate && (Global.productPageDisplay=='PAInsurance')">
                <div id="pa-insure-hero" class="header-area" style="background-image: linear-gradient(180deg, #0f5fa9 0, #003b71 45%); background-color: #0f5fa9;">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-md-8 py-50 col-content-center">
                                <h2 v-if="Global.quote.prefLang !== 'zh'" class="text-white">{{$t("pa.header01")}}</h2>
                                <h2 v-else class="h1 text-white">{{$t("pa.header01")}}</h2>
                                <p v-if="Global.quote.prefLang !== 'zh'" class="text-white font-weight-normal">{{$t("pa.header02")}}<br>{{$t("pa.header03")}}</p> 
                                <p v-else class="text-white font-weight-normal">{{$t("pa.header02")}}{{$t("pa.header03")}}</p> 
                                <a v-on:click="getProductQuote('pa')" data-selenium-name="get-quote-pa" rel="follow" title="Personal Accident Insurance" class="btn btn-orange btn-lg px-5 mt-5">{{$t("pa.headerButton")}}</a>
                            </div>
                            <div class="col-xs-12 col-md-4 col-content-bottom">
                                <img width="340" height="256" class="img-responsive" src="images/product/pa-hero.png" alt="Online Personal Accident Insurance in Thailand">
                            </div>                            
                        </div>
                    </div>
                </div>
                <div class="info-area py-50">
                    <div class="container">
                        <div class="row mb-5">
                            <div class="col-xs-12 col-sm-4 col-md-5">
                                <img width="280" height="373" class="img-responsive mx-auto" src="images/product/pa-group.jpg" alt="Online Personal Accident Insurance with Roojai.com">
                            </div>
                            <div class="col-xs-12 col-sm-8 col-md-7 col-content-center">
                                <h3>{{$t("pa.section01.h3")}}</h3>
                                <p v-if="Global.quote.prefLang !== 'zh'">{{$t("pa.section01.p")}}</p>
                                <ol class="mb-5">
                                    <li>{{$t("pa.section01.li01")}}</li>
                                    <li>{{$t("pa.section01.li02")}}</li>
                                    <li>{{$t("pa.section01.li03")}}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="care-card py-75" style="background-image: linear-gradient(180deg, #f1f2f6 0, #dfe4ea 100%);">
                        <div class="container">
                            <div class="row">
                                <div class="col-xs-12 col-sm-3 col-sm-offset-1 col-md-2 col-md-offset-2">
                                    <img width="111" height="177" class="img-responsive mx-auto" src="images/product/ecare-card.png" alt="Digital Roojai Care Car">
                                </div>
                                <div class="col-xs-12 col-sm-8 col-md-6">
                                    <div class="lead"><br>{{$t("pa.section02.h301")}}<br v-if="Global.quote.prefLang !== 'zh'">{{$t("pa.section02.h302")}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hospitals container">
                        <div class="row card-hospital align-items-center my-75 mb-5" style="background-image: linear-gradient(120deg, #0f5fa9 0, #003b71 45%);">
                            <div class="col-xs-12 col-sm-10 col-md-8 col-content-center p-5">
                                <h2 class="text-white">{{$t("pa.section03.h2")}}</h2>
                                <p class="lead text-white">{{$t("pa.section03.p")}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Critical Illness -->
            <div id="product-critical-illness-insurance" v-if="!isCoBrandingAffiliate && (Global.productPageDisplay=='CIInsurance')">
                <div id="ci-insure-hero" class="header-area" style="background-image: linear-gradient(180deg, #0f5fa9 0, #003b71 45%); background-color: #0f5fa9;">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 col-md-8 py-50 col-content-center">
                                <h2 class="text-white">{{ $t("ci.header") }}</h2>
                                <p class="lead text-primary">{{ $t("ci.subHeader") }}</p>
                                <p class="text-white">{{ $t("ci.headerDesc") }}</p>
                                <a v-on:click="getProductQuote('ci')" data-selenium-name="get-quote-ci" rel="follow" title="Get Quote Online Critical Illness Insurance" class="btn btn-orange btn-lg px-5 mt-5">{{$t("ci.headerButton")}}</a>
                            </div>
                            <div class="col-xs-12 col-md-4 col-content-bottom">
                                <img width="350" height="350" class="img-responsive" src="images/product/ci-hero.png" alt="Critical Illness Insurance">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-area py-50">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-7 col-content-center text-left">          
                                <h2>{{ $t("ci.section01.header") }}</h2>
                                <ul>
                                    <li>{{ $t("ci.section01.item01") }}</li>
                                    <li>{{ $t("ci.section01.item02") }}</li>
                                    <li>{{ $t("ci.section01.item03") }}</li>
                                    <li>{{ $t("ci.section01.item04") }}</li>
                                </ul>
                            </div>
                            <div class="col-12 col-md-5">
                                <img width="350" height="350" class="img-responsive mx-auto" src="images/product/ci-group-1.png" alt="insurance coverage for critical illness with Roojai">
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-md-7 col-md-push-5 col-content-center text-left">
                                <h2 >{{ $t("ci.section02.header") }}</h2>
                                <ol>
                                    <li>{{ $t("ci.section02.item01") }}</li>
                                    <li>{{ $t("ci.section02.item02") }}</li>
                                    <li>{{ $t("ci.section02.item03") }}</li>
                                    <li>{{ $t("ci.section02.item04") }}</li>
                                </ol>
                            </div>
                            <div class="col-md-5 col-md-pull-7 text-center">
                                <img width="350" height="350" class="img-responsive mx-auto" src="images/product/ci-group-2.png" alt="buy your critical illness insurance policy online with Roojai">
                            </div>
                        </div>
                        <h3 class="mt-5">{{ $t("ci.coverageDetailHeader") }}</h3>
                        <p class="mb-5">{{ $t("ci.coverageDetailMsg") }}</p>
                        <div class="table-responsive" style="overflow-x: auto;" :class="Global.quote.prefLang === 'id'?'':'mt-4'">
                            <table class="table table-striped table-bordered table-ci-plan">
                                <thead class="bg-secondary">
                                    <tr>
                                        <th scope="col">{{ $t("ci.tbl01.th-col01") }}</th>
                                        <th scope="col">{{ $t("ci.tbl01.th-col02") }}</th>
                                        <th scope="col">{{ $t("ci.tbl01.th-col03") }}</th>
                                        <th scope="col">{{ $t("ci.tbl01.th-col04") }}</th>
                                        <th scope="col">{{ $t("ci.tbl01.th-col05") }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">{{ $t("ci.tbl01.td-row01") }}</td>
                                        <td>200.000.000</td>
                                        <td>500.000.000</td>
                                        <td>800.000.000</td>
                                        <td>1.000.000.000</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">{{ $t("ci.tbl01.td-row02") }}</td>
                                        <td>200.000.000</td>
                                        <td>500.000.000</td>
                                        <td>800.000.000</td>
                                        <td>1.000.000.000</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">{{ $t("ci.tbl01.td-row03") }}</td>
                                        <td>200.000.000</td>
                                        <td>500.000.000</td>
                                        <td>800.000.000</td>
                                        <td>1.000.000.000</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">{{ $t("ci.tbl01.td-row04") }}</td>
                                        <td>200.000.000</td>
                                        <td>500.000.000</td>
                                        <td>800.000.000</td>
                                        <td>1.000.000.000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h3 class="mt-5">{{ $t("ci.coverageDetailHeader2") }}</h3>
                        <p class="mb-5">{{ $t("ci.coverageDetailMsg2") }}</p>
                        <div class="table-responsive" style="overflow-x: auto;" :class="Global.quote.prefLang === 'id'?'':'mt-4'">
                            <table class="table table-striped table-bordered table-ci-plan">
                                <thead class="bg-secondary">
                                    <tr>
                                        <th scope="col">{{ $t("ci.tbl02.th-col01") }}</th>
                                        <th scope="col">{{ $t("ci.tbl02.th-col02") }}</th>
                                        <th scope="col">{{ $t("ci.tbl02.th-col03") }}</th>
                                        <th scope="col">{{ $t("ci.tbl02.th-col04") }}</th>
                                        <th scope="col">{{ $t("ci.tbl02.th-col05") }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">{{ $t("ci.tbl02.td-row01") }}</td>
                                        <td>200.000</td>
                                        <td>400.000</td>
                                        <td>600.000</td>
                                        <td>1.000.000</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">{{ $t("ci.tbl02.td-row02") }}</td>
                                        <td>200.000.000</td>
                                        <td>500.000.000</td>
                                        <td>800.000.000</td>
                                        <td>1.000.000.000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <small class="mt-4">{{ $t("ci.tbl02.note1") }}</small> <br>
                        <small class="">{{ $t("ci.tbl02.note2") }}</small>
                    </div>
                </div>
            </div>

            <!-- Detail area for all product and MotorCar -->
            <div id="usp-info" class="section">
                <div class="container" v-if="Global.productPageDisplay==null || ( displayAllProducts || Global.productPageDisplay=='carInsurance')">
                    <div class="row">
                        <!-- <div class="col-xs-12 col-sm-6">
                            <div class="media px-lg-4 mb-5">
                                <div class="media-left">
                                    <img width="150" height="150" class="media-object mr-md-3" src="images/product/usp/icon-special-price.svg" alt="Special Price for Annual Payments">
                                </div>
                                <div class="media-body ml-3">
                                    <h2 class="mt-0">{{$t("car.card01.h2")}}</h2>
                                    <p v-if="Global.productPageDisplay==null">{{$t("car.card01.p")}}</p> 
                                    <p v-else-if="Global.productPageDisplay=='carInsurance'">{{$t("car.card01.p-car")}}</p> 
                                </div>
                            </div>
                        </div> -->
                        <div class="col-xs-12 col-sm-6" v-if="( Global.brokerInfo.productType == 'allProduct' || displayHealthProduct )">
                            <div class="media px-lg-4 mb-5">
                                <div class="media-left">
                                    <img width="150" height="150" class="media-object mr-md-3" src="images/product/usp/icon-easy-and-fast-claim.svg" alt="Easy and Fast Claim">
                                </div>
                                <div class="media-body ml-3">
                                    <h2 class="mt-0">{{$t("car.card02.h2")}}</h2>
                                    <p>{{$t("car.card02.p")}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6" v-if="( displayHealthProduct || (Global.brokerInfo.productType == 'allProduct' && Global.productPageDisplay!='carInsurance')  )">
                            <div class="media px-lg-4 mb-5">
                                <div class="media-left">
                                    <img width="150" height="150" class="media-object mr-md-3" src="images/product/usp/icon-cashless-payment-service.svg" alt="Cashless Payment Service">
                                </div>
                                <div class="media-body ml-3">
                                    <h2 class="mt-0">{{$t("car.card03.h2")}}</h2>
                                    <p>{{$t("car.card03.p")}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6" v-if="( displayHealthProduct || (Global.brokerInfo.productType == 'allProduct' && Global.productPageDisplay !='carInsurance') )">
                            <div class="media px-lg-4 mb-5">
                                <div class="media-left">
                                    <img width="150" height="150" class="media-object mr-md-3" src="images/product/usp/icon-network-hospitals.svg" alt="2000+ Hospital Network">
                                </div>
                                <div class="media-body ml-3">
                                    <h2 class="mt-0">{{$t("car.card04.h2")}}</h2>
                                    <p>{{$t("car.card04.p")}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-xs-12 col-sm-6">
                            <div class="media px-lg-4 mb-5">
                                <div class="media-left">
                                    <img width="150" height="150" class="media-object mr-md-3" src="images/product/usp/icon-payment-in-instalments.svg" alt="Pay in Instalments with Credit and Debit Cards">
                                </div>
                                <div class="media-body ml-3">
                                    <h2 class="mt-0">{{$t("car.card05.h2")}}</h2>
                                    <p>{{$t("car.card05.p")}}</p>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-xs-12 col-sm-6" v-if="( Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('motorcar'))">
                            <div class="media px-lg-4 mb-5">
                                <div class="media-left">
                                    <img width="150" height="150" class="media-object mr-md-3" src="images/product/usp/icon-era.svg" alt="24 hour Emergency Roadside Assistance">
                                </div>
                                <div class="media-body ml-3">
                                    <h2 class="mt-0">{{$t("car.card06.h2")}}</h2>
                                    <p>{{$t("car.card06.p")}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6" v-if="( Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('motorcar'))">
                            <div class="media px-lg-4 mb-5">
                                <div class="media-left">
                                    <img width="150" height="150" class="media-object mr-md-3" src="images/product/usp/icon-video-car-inspection.svg" alt="Car Inspection via Video Call">
                                </div>
                                <div class="media-body ml-3">
                                    <h2 class="mt-0">{{$t("car.card07.h2")}}</h2>
                                    <p>{{$t("car.card07.p")}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6" v-if="( Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('motorcar'))">
                            <div class="media px-lg-4 mb-5">
                                <div class="media-left">
                                    <img width="150" height="150" class="media-object mr-md-3" src="images/product/usp/icon-partner-garage.svg" alt="700+ Trusted Partner Car Repair Garages">
                                </div>
                                <div class="media-body ml-3">
                                    <h2 class="mt-0">{{$t("car.card08.h2")}}</h2>
                                    <p>{{$t("car.card08.p")}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Footer product link for GA and TB -->
            <div id="product-empty" v-if="!isCoBrandingAffiliate">
                <div class="container my-75">
                    <div class="card card-all-products" style="background-image: linear-gradient(120deg, #0f5fa9 0, #003b71 70%);">
                        <div class="card-body text-center">
                            <h2 class="header-caption text-white">
                                <span class="text-primary">{{$t("headerCaption.firstline")}}</span>
                                <br>
                                {{$t("headerCaption.secondline")}}  
                            </h2>
                            <div id="product-link" class="row product-link justify-content-center my-5 mx-0" :class="classLocale">
                                <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="( Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('motorcar'))">
                                    <a class="hero-link" data-selenium-name="car-btn" v-on:click="selectProduct('carInsurance')" rel="follow" title="ประกันรถยนต์">
                                        <div class="card card-hero-icon card-hero-car mb-3 mx-auto">
                                            <div class="card-body py-3 px-3 text-center">
                                                <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/car-icon.svg" alt="Car insurance"><!-- <p class="mb-0">Car insurance</p> -->
                                                <p class="mb-0">{{$t("motorCar")}}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="(Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('cardio'))">
                                    <a class="hero-link" data-selenium-name="extended-warranty-btn" v-on:click="selectProduct('heartdiseases')" rel="follow" title="Extended Warranty Insurance">
                                        <div class="card card-hero-icon card-hero-extended mb-3 mx-auto">
                                            <div class="card-body py-3 px-1 text-center">
                                                <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/heart-icon.svg" alt="Extended Warranty Insurance"> 
                                                <p class="mb-0">{{$t("heartdiseases")}}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="(Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('dengue'))">
                                    <a class="hero-link" data-selenium-name="motorbike-btn" v-on:click="selectProduct('mosquito')" rel="follow" title="Motorbike insurance">
                                        <div class="card card-hero-icon card-hero-moto mb-3 mx-auto">
                                            <div class="card-body py-3 px-2 text-center">
                                                <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/mosquito-icon.svg" alt="Motorbike insurance">
                                                <p class="mb-0" v-if="Global.quote.prefLang !== 'en'">{{$t("motorBike")}}</p>
                                                <p class="mb-0" v-else>
                                                    <i18n tag="span" path="motorBikeHtml">
                                                        <span>{{$t('motorBikeBike')}}<br></span>
                                                        <span>{{$t('motorBikeInsurance')}}</span>
                                                    </i18n>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="(Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('pa'))">
                                    <a class="hero-link" data-selenium-name="personal-accident-btn" v-on:click="selectProduct('PAInsurance')" rel="follow" title="Personal accident insurance">
                                        <div class="card card-hero-icon card-hero-pa mb-3 mx-auto">
                                            <div class="card-body py-3 px-2 text-center">
                                                <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/pa-icon.svg" alt="Personal accident insurance">
                                                <p class="mb-0">
                                                    <i18n tag="span" path="paHtml">
                                                        <!-- <span>{{$t('paInsurance')}}</span> -->
                                                        <span>
                                                            {{$t('paPersonal')}}
                                                        </span>
                                                    </i18n>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="(Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('cancer'))">
                                    <a class="hero-link" data-selenium-name="cancer-btn" v-on:click="selectProduct('cancerInsurance')" rel="follow" title="Cancer insurance">
                                        <div class="card card-hero-icon card-hero-cancer mb-3 mx-auto">
                                            <div class="card-body py-3 px-2 text-center">
                                                <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/cancer-icon.svg" alt="Cancer insurance">
                                                <p class="mb-0">
                                                    {{$t("cancerInsurance")}}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-xs-4 col-md-2 px-2 px-md-3" v-if="(Global.brokerInfo.productType == 'allProduct' || Global.brokerInfo.productType.includes('ci'))">
                                    <a class="hero-link" data-selenium-name="critical-illness-btn" v-on:click="selectProduct('CIInsurance')" rel="follow" title="Critical illness insurance">
                                        <div class="card card-hero-icon card-hero-ci mb-3 mx-auto">
                                            <div class="card-body py-3 px-2 text-center">
                                                <img width="70" height="70" class="img-responsive mx-auto mb-2" src="images/product/ci-icon.svg" alt="Critical illness  insurance">
                                                <p class="mb-0">
                                                    <i18n tag="span" path="ciHtml">
                                                        <span>{{$t('ciInsurance.line1')}}</span>
                                                        <span><br>{{$t('ciInsurance.line2')}}</span>
                                                    </i18n>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <p class="lead text-white mx-5" >{{$t("headerDesc")}}</p>                           
                        </div>
                    </div>
                </div>
            </div>

            <!-- Co-branding affiliate banner -->
            <div id="co-branding-affiliate" class="hero-area central-hero" style="background-image: linear-gradient(120deg, #0f5fa9 0, #003b71 70%);padding:30px 0px;" v-if="isCoBrandingAffiliate">
                <div class="container text-center">
                    <h1 class="header-caption text-white">{{coBrandingDisplay.content.heading[Global.quote.prefLang]}}</h1>
                    <h2 class="text-primary">{{coBrandingDisplay.content.subHeading[Global.quote.prefLang]}}</h2>
                    <div class="promotions">
                        <img v-for="(promotion, index) in coBrandingDisplay.content.promotionImageList" :key="index" :src="promotion[Global.quote.prefLang]" class="img-responsive" width="207" height="110">
                    </div>
                    <a v-on:click="getProductQuote('car')" data-selenium-name="co-branding-car" rel="follow" title="ประกันรถยนต์" class="btn btn-orange btn-lg" style="padding: 1.5rem 3rem; margin: 0.5rem;" v-if="coBrandingDisplay.content.productList.indexOf('MotorCar') > -1">{{$t('motorCarCoBranding')}}</a>
                    <a v-on:click="getProductQuote('heartdiseases')" data-selenium-name="co-branding-ew" rel="follow" title="ประกันอะไหล่รถยนต์" class="btn btn-orange btn-lg" style="padding: 1.5rem 3rem;  margin: 0.5rem;" v-if="coBrandingDisplay.content.productList.indexOf('ExtendedWarranty') > -1">{{$t('ewCoBranding')}}</a>
                    <a v-on:click="getProductQuote('mosquito')"  rel="follow" data-selenium-name="co-branding-motorbike" title="ประกันมอเตอร์ไซค์" class="btn btn-orange btn-lg" style="padding: 1.5rem 3rem; margin: 0.5rem;" v-if="coBrandingDisplay.content.productList.indexOf('MotorBike') > -1">{{$t('motorBikeCoBranding')}}</a>
                    <a v-on:click="getProductQuote('pa')" data-selenium-name="co-branding-pa" rel="follow" title="ประกันอุบัติเหตุส่วนบุคคล" class="btn btn-orange btn-lg" style="padding: 1.5rem 3rem; margin: 0.5rem;" v-if="coBrandingDisplay.content.productList.indexOf('PersonalAccident') > -1">{{$t('paCoBranding')}}</a>
                    <a v-on:click="getProductQuote('health')" data-selenium-name="co-branding-health" rel="follow" title="ประกันมะเร็ง" class="btn btn-orange btn-lg" style="padding: 1.5rem 3rem; margin: 0.5rem;" v-if="coBrandingDisplay.content.productList.indexOf('Health') > -1">{{$t('cancerCoBranding')}}</a>
                    <a v-on:click="getProductQuote('ci')" data-selenium-name="co-branding-ci" rel="follow" title="ประกันโรคร้านแรง" class="btn btn-orange btn-lg" style="padding: 1.5rem 3rem; margin: 0.5rem;" v-if="coBrandingDisplay.content.productList.indexOf('CriticalIllness') > -1">{{$t('ciCoBranding')}}</a>
                </div>
            </div>

            <!-- Co-Branding affiliate central terms -->
            <div class="section central-terms" style="padding:50px 0px;" v-if="isCoBrandingAffiliate">
                <div class="container">
                    <h4>{{coBrandingDisplay.termAndConditions.header[Global.quote.prefLang]}}</h4>
                    <ol>
                        <li v-for="(tc, index) in coBrandingDisplay.termAndConditions.details" :key="index">
                            {{tc.list[Global.quote.prefLang]}} 
                            <ul v-if="tc.subList">
                                <li v-for="(sub, index) in tc.subList" :key="index">{{sub[Global.quote.prefLang]}}</li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </div>
        </main>

        <div class="modal" id="myPleaseWait" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false" data-keyboard="false" style="display:none; padding-top:60px;">
            <div class="modal-dialog modal-sm">
                <div class="modal-content" style="height: auto; border-radius: 6px;">
                    <div class="modal-header">
                        <h4 class="modal-title text-center">
                            {{$t("pleaseWait")}}
                        </h4>
                    </div>
                    <div class="modal-body">
                        <div class="progress">
                            <div class="progress-bar progress-bar-info progress-bar-striped active" style="width: 100%">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Global from '@/store/global.js';
// import globalVariables from '../store/globalVariables';
import mixin from '../components/Mixin.vue';

export default {
    name: 'Product',
    mixins: [mixin],
    components: {
        // componentFooter: () => import('./footer'),
    },
    data(){
        return {
            Global,
            isCoBrandingAffiliate : false,
            coBrandingDisplay: null,
            intermediaryLogoUrl: null,
            productPageDisplay: null,
        }
    },
    async created() {
        document.title = "Roojai Agent Platform";
        
        this.getDetailBroker();
        // else if(!jQuery.isEmptyObject(this.$route.query.lang) && this.$route.query.lang.toLowerCase()=="zh"){
        //     Global.quote.prefLang = "zh";
        // }
        Global.isAgentCustomerView = true;
        // Global.isNotProductPage = false;     
        // this.getProductPageIntermediaryLogo().then(resolve =>{
        //     this.intermediaryLogoUrl = resolve;
        // });   
        if (this.$route.query.channel !== undefined){
            Global.decryptReferralLink = this.$route.query.channel;
        }
    },
    methods: {
        getInfo: async function(){
            if( Global.agentInfo.intermediaryReptID && Global.agentInfo.intermediaryReptEmail ){
                return true;
            }

            $('#myPleaseWait').modal('show');
            await this.getAgentInfo(Global.agentInfo.intermediaryReptID);
            $('#myPleaseWait').modal('hide');

            if( !Global.agentInfo.intermediaryAccountID && !Global.agentInfo.intermediaryReptID ){
                this.$router.replace("/");
            }
        },
        getProductQuote: async function(value) {
            if (value == 'car' ) {
                this.$router.push({ path: '/mv/input', query: { lang: Global.quote.prefLang} })
            }else if(value == 'ci'){
                this.$router.push({ path: '/', query: { lang: Global.quote.prefLang} })
            }else if(value == 'pa'){
                this.$router.push({ path: '/pa-input', query: { lang: Global.quote.prefLang} })
            }else if(value == 'mosquito'){
                this.$router.push({ path: '/', query: { lang: Global.quote.prefLang, preselected: 'Dengue'} })
            }else if(value == 'heartdiseases'){
                this.$router.push({ path: '/', query: { lang: Global.quote.prefLang, preselected: 'Cardiovascular'} })
            }else if(value == 'health'){
                this.$router.push({ path: '/', query: { lang: Global.quote.prefLang, preselected: 'Cancer'} })
            }
        },
        selectProduct: function(productType){
            Global.gaLandingPageHistory.push(Global.productPageDisplay);
            // Global.productPageDisplay = productType;
            Global.productPageDisplay = productType
            window.scrollTo(0,0);
            history.pushState(productType, "", "/quote#/product");
        },
        async getDetailBroker(){
            if(!jQuery.isEmptyObject(this.$route.query.lang) && this.$route.query.lang.toLowerCase()=="en"){
                Global.quote.prefLang = "en";
            }
           
            if (Global.brokerInfo.rid != null) {
                await this.getAgentInfo();
                this.setSesionStorage();
            }
            else{
                await this.getSesionStorage();
                this.loading('hide')
            }
        },
        getSesionStorage(){
            if (sessionStorage.getItem("firstname") !== null) {
                Global.brokerInfo.firstname = sessionStorage.getItem(
                    "firstname"
                );
            }
            if (sessionStorage.getItem("lastname") !== null) {
                Global.brokerInfo.lastname = sessionStorage.getItem(
                    "lastname"
                );
            }
            if (sessionStorage.getItem("intermediaryAccountID") !== null) {
                Global.brokerInfo.intermediaryAccountID = sessionStorage.getItem(
                    "intermediaryAccountID"
                );
            }
            if (sessionStorage.getItem("intermediaryReptEmail") !== null) {
                Global.brokerInfo.intermediaryReptEmail = sessionStorage.getItem(
                    "intermediaryReptEmail"
                );
            }
            if (sessionStorage.getItem("intermediaryReptID") !== null) {
                Global.brokerInfo.intermediaryReptID = sessionStorage.getItem(
                    "intermediaryReptID"
                )   ;
            }
            if (sessionStorage.getItem("brokerProductType") !== null) {
                Global.brokerInfo.productType = sessionStorage.getItem("brokerProductType");
            }
            if (sessionStorage.getItem("useInstallment") !== null) {
                Global.brokerInfo.useInstallment = sessionStorage.getItem("useInstallment") == "true";
            }
            
            if (sessionStorage.getItem("isAgentCustomerView") !== null) {
                Global.isAgentCustomerView = sessionStorage.getItem("isAgentCustomerView") == "true";
            }
            if (sessionStorage.getItem("channel") !== null) {
                Global.quote.channel = sessionStorage.getItem("channel");
            }
        },
        setSesionStorage(){
            if (sessionStorage.getItem("firstname") === null) {
                sessionStorage.setItem('firstname', Global.brokerInfo.firstname);
            }
            if (sessionStorage.getItem("lastname") === null) {
                sessionStorage.setItem('lastname', Global.brokerInfo.lastname);
            }
            if (sessionStorage.getItem("intermediaryReptID") === null) {
                sessionStorage.setItem('intermediaryReptID', Global.brokerInfo.intermediaryReptID);
            }
            if (sessionStorage.getItem("intermediaryReptEmail") === null) {
                sessionStorage.setItem('intermediaryReptEmail', Global.brokerInfo.intermediaryReptEmail);
            }
            if (sessionStorage.getItem("intermediaryAccountID") === null) {
                sessionStorage.setItem('intermediaryAccountID', Global.brokerInfo.intermediaryAccountID);
            }
            if (sessionStorage.getItem("brokerProductType") === null) {
                sessionStorage.setItem('brokerProductType', Global.brokerInfo.productType);
            }
            if (sessionStorage.getItem("isAgentCustomerView") === null) {
                sessionStorage.setItem("isAgentCustomerView","true");
            }
            if (sessionStorage.getItem("useInstallment") === null) {
                sessionStorage.setItem("useInstallment",Global.brokerInfo.useInstallment);
            }
        }
        // getProductQuote: function(productType){
        //     Global.isNotProductPage = true;
        //     this.gotoProduct(productType);
        // }
    },
    computed: {
        classLocale: function(){
            return "product-link-" + Global.quote.prefLang
        },
        classTableCancerPlan: function(){
            return Global.quote.prefLang === 'zh' ? "table-cancer-plan-cn" : "table-cancer-plan";
        },
        displayHealthProduct: function(){
            let values = ["ci","cancer","cardio","dengue","pa"] 
            return values.every(value => {
                return Global.brokerInfo.productType.indexOf(value) !== -1;
            });
        },
        displayAllProducts: function() {
            if (Global.productPageDisplay==null || ["allProduct"].indexOf(Global.productPageDisplay) > -1) {
                return true;
            } else if (["carInsurance", "heartdiseases", "mosquito", "PAInsurance", "cancerInsurance", "CIInsurance"].indexOf(Global.productPageDisplay) > -1) {
                return false;
            } else {
                return true;
            }
        }
    },
    watch: {
        "Global.quote.prefLang": function(value){
            this.$root.$i18n.locale = value;
        },
    },
    mounted() {
        // this.imgLazyLoading();
        // this.isCoBrandingAffiliate = JSON.parse(Global.productPageConfig.affiliateBrokerNames).includes(Global.agentInfo.brokerName) && Global.agentInfo.accountSource === "Affiliate";
        // if(this.isCoBrandingAffiliate){
            // this.coBrandingDisplay = JSON.parse(Global.productPageConfig[Global.agentInfo.brokerName]);
        // }
        // this.getProductPageIntermediaryLogo().then(resolve =>{
        //     this.intermediaryLogoUrl = resolve;
        // });
        $(window).off("popstate");
        $(window).on("popstate", function(event){
            let popPage = Global.gaLandingPageHistory.pop();
            Global.productPageDisplay = popPage;
        });
    },
}
</script>
<i18n>
{
  "en": {
    "headerCaption": {
        "firstline": "Get your insurance quote in just a few clicks",
        "secondline": "",
        "roojai" : "Roojai.com",
        "online": "online",
        "insurance": "insurance"
    },
    "headerDesc": "Roojai. Simple, affordable, and reliable online insurance Get covered now",
    "motorCar":  "Car insurance",
    "heartdiseases": "Heart Diseases",
    "motorBike": "Mosquito",
    "motorBikeBike": "Mosquito",
    "motorBikeInsurance": "borne",
    "motorBikeHtml": "{0}{1}",
    "cancerInsurance": "Cancer Insurance",
    "ciHtml":"{0}{1}",
    "ciInsurance": {
        "line1": "Critical Illness",
        "line2": "Insurance"
    },
    "pa": "Personal Accident Insurance",
    "covidInbountTravel": "Go Thai Inbound Travel insurance",
    "motorCarCoBranding": "เช็คราคาประกันรถยนต์",
    "motorBikeCoBranding": "เช็คราคาประกันจักรยานยนต์",
    "ewCoBranding": "เช็คราคาประกันอะไหล่รถยนต์",
    "cancerCoBranding": "เช็คราคาประกันมะเร็ง",
    "paCoBranding": "เช็คราคาประกันอุบัติเหตุส่วนบุคคล",
    "ciCoBranding": "เช็คราคาประกันโรคร้ายแรง",
    "paHtml":"{1}{0}",
    "paInsurance":"Insurance",
    "paPersonal":"Personal Accident",
    "camera": {
        "line1": "Special Price for ",
        "line2": "Annual Payments "
    },
    "mobileApp": {
        "line1": "Easy and Fast Claims",
        "line2" : ""
    },
    "service": {
        "line1": "Cashless Payment Service",
        "line2" : ""
    },
     "guarantee": {
        "line1": "2000+ Hospital Network",
        "line2" : ""
    },
     "warranty": {
        "line1": "Pay in Instalments ",
        "line2" : "with Credit and Debit Cards "
    },
    "moneyBack": {
        "line1": "Car Inspection via Video Call ",
        "line2" : ""
    },
    "rsa": {
        "line1": "24 hour Emergency Roadside Assistance",
        "line2" : ""
    },
    "car": {
        "header01": "Comprehensive Car Insurance in Indonesia",
        "header02": "Roojai offers Comprehensive Car Insurance starting from IDR 225k/month for your vehicle. Protect your car from the risks of damage and loss wherever you are. Easily customize the protection benefits that suit your needs and budget with just a few clicks on your mobile phone.",
        "headerButton": "Get a Quote",
        "card01": {
            "h2": "Special Price for Annual Payments",
            "p": "Whilst you can choose a monthly insurance payment, you have the option to get a 13% discount on every purchase of health insurance products at Roojai by choosing annual payments which make buying insurance more affordable and cost-effective.",
            "p-car" : "Whilst you can choose a monthly insurance payment, you have the option to get a 6% discount on every purchase of the car insurance at Roojai by choosing annual payments which make buying insurance more affordable and cost-effective. "
        },
        "card02": {
            "h2": "Easy and Fast Claims",
            "p": "Cashless or reimbursement insurance claims can be made quickly and easily, and most importantly its paperless! Our claims team will help you process your claim until it is completed. Also, find out your claim status simply through the Whatsapp message we sent you."
        },
        "card03": {
            "h2": "Cashless Payment Service",
            "p": "You don’t need to wait longer to complete your inpatient bills after getting medical treatment at Roojai’s hospital network, as it can be paid cashless. You only need to sign some documents and check out from the hospital."
        },
        "card04": {
            "h2": "2000+ Hospital Network",
            "p": "With more than 2000 of Roojai’s hospital networks throughout Indonesia, you can easily find a trusted hospital nearest your area. Always make sure you get medical treatment at our hospital network to get the best service possible.",
        },
        "card05": {
            "h2": "Pay in Instalments with Credit and Debit Cards",
            "p": "Figuring out how to pay insurance conveniently? Instalment payments with credit and debit cards is one of the insurance payment options available at Roojai, available to provide more flexibility and affordability in managing your expenses."
        },
        "card06": {
            "h2": "24 hour Emergency Roadside Assistance",
            "p": "ERA is available 24 hours every day, making it easier for you to get assistance on the road when you experience a car breakdown, road accident, flat tire, keys left in the car, and more.",
        },
        "card07": {
            "h2": "Car Inspection via Video Call",
            "p": "One big advantage of insurance with Roojai is the online car inspections via video calls. This service allows your vehicle to be inspected faster and more comfortably because it can be done anywhere."
        },
        "card08": {
            "h2": "700+ Trusted Partner Car Repair Garages",
            "p": "Find the nearest car repair shop or authorized dealer garage in your area, to get the best service when repairing your vehicle."
        }
    },
    "hd": {
        "header01": "Online Cardiovascular Insurance from Roojai",
        "header02": "Protect yourself and your family from the risks arising from cardiovascular disease. Build the cover that you want best by selecting the benefits & the sum insured and pay online either annually or by instalment. Get a cash lump sum of up to 1 billion IDR. Starting price only 50,000 IDR per month. ",
        "headerButton": "Get Quote",
        "section01": {
            "header01": "Why do I need to get cardiovascular disease insurance?",
            "p01": "Protection from the high cost of cardiovascular treatment.",
            "p02": "This insurance can serve as a top up of your health insurance/BPJS Kesehatan. If you are diagnosed and require surgery due to Ischemic or Haemorrhagic Stroke, Acute Heart Attack, or coronary artery disease, you can submit a claim even if some or all of your hospital costs are covered by other insurance.",
            "p03": "Cash lump sum can help financially should you need to take time off work due to your cardiovascular disease treatment.",
            "p04": "New treatment methods are often not covered by traditional health insurance, this product gives you extra peace of mind should the worse happen.",
        }
    },
    "mb": {
        "header01": "Insurance for Mosquito-borne Diseases in Indonesia",
        "header02": "When you live in a country rife with mosquitos, it is especially important to have access to affordable coverage for mosquito-borne illnesses such as Dengue Fever, Malaria, Chikugunya, Zika, and Yellow Fever. Get coverage for you and your family to receive cashless treatments at over 2.000 Roojai partner hospitals.",
        "headerButton": "Get a Quote",
        "body_h3": "Details of Comprehensive Motorcycle Insurance Type 1",
        "section01": {
            "h4": "Why it is important to have insurance for mosquito-borne diseases?",
            "p01": "Living in Indonesia, a tropical climate, makes you vulnerable to tropical diseases, especially those that are mosquito-borne (Dengue Fever, Malaria, Chikungunya, Zika, and Yellow Fever).",
            "p02": "The medical expense for these mosquito-borne illnesses and their complications can pose a serious financial challenge for many people. Insurance for Mosquito-borne Disease can cover the cost of your treatment while in hospital so that it doesn’t drain your hard-earned saving.",
            "p03": "You can access over 2,000 trusted Roojai hospital networks to get the best treatment. ",
        },
        "section02": {
            "h301": "Exclusive privileges for Roojai customers",
            "h302": "Simply present your digital Roojai Care Card and ID card/passport to receive medical treatment without advance payment."
        },
        "section03": {
            "h2": "Roojai network hospitals",
            "p": "Roojai personal accident insurance customers can receive medical treatment for accidents without having to make an advance payment according to the sum insured in the policy at over 2.000 network hospitals. "
        }
    },
    "pa": {
        "header01": "Online Personal Accident Insurance in Indonesia",
        "header02": "Customise plan and insurance coverage depending on your lifestyle.",
        "header03": "Simple to buy online.",
        "headerButton": "Get a Quote",
        "section01": {
            "h3": "What are the unique benefits of personal accident insurance? ",
            "p": "Personal accident insurance is a kind of insurance that provides financial compensation for the long-term health-related consequences of an accident. Regular health insurance normally does not provide this. ",
            "li01": "Get financial compensation for the loss of income or to cover the cost of medical treatment resulting from an accident.",
            "li02": "Get benefits for in-patient treatment cost paid directly to the hospital (cashless).",
            "li03": "Get additional coverage for accidents resulting from your hobby sports activities and other unique options."
        },
        "section02": {
            "h301": "Exclusive privileges for Roojai customers",
            "h302": "Simply present your digital Roojai Care Card and ID card/passport to receive medical treatment without advance payment."
        },
        "section03": {
            "h2": "Roojai network hospitals",
            "p": "Roojai personal accident insurance customers can receive medical treatment for accidents without having to make an advance payment according to the sum insured in the policy at over 2.000 network hospitals. "
        }
    },
    "cancer": {
        "header": "Online Insurance for Cancer",
        "subHeader": "Fully flexible, affordable, and online cover for serious illnesses. Build the cover that suits you best by selecting the benefits & the sum insured, get approval immediately, and pay online. ",
        "headerButton": "Get Quote",
        "section01": {
            "h2": "Why You Need Cancer Insurance?",        		
            "p01": "Protection from the high cost of cancer treatment.",
            "p02": "This insurance can serve as a top up of your health insurance/BPJS program. If you are diagnosed with invasive cancer, early stage, and late stage.",
            "p03": "Cash lump sum can help financially should you to take time off work due to cancer treatment. ",
            "p04": "New treatment methods are often not covered by traditional health insurance, this product gives you extra peace of mind should the worse happen."
        },
        "section02": {
            "h3": "We will be providing continuous insurance coverage for your illness as specified in the policy if you make a payment: ",
            "col01": "By the renewal due date ",
            "col02": "Before the renewal due date",
            "col03": "Or within the grace period (30 days)"
        },
        "section03": {
            "h4": "What makes cancer insurance from Roojai different?",
            "lo01": "Simple. Roojai Cancer insurance has been designed to minimize the number of exclusions, which gives customer the most straightforward and easy-to-understand policies.",
            "lo02": "Customized Benefits. You can choose your preferred benefits based on your needs and budget.",
            "lo03": "Flexibility. Our Cancer cover is one of the modules that make up our Critical Illness insurance product. You can choose only this module, or you can also add one or more of the other coverages: cardiovascular, mosquito-borne diseases, neurological illnesses, and kidney failure."
        },
	},
    "ci": {
        "header": "Roojai Online Critical Illness Insurance",
        "subHeader": "Easily buy and customise insurance coverage with Roojai",
        "headerDesc": "Fully flexible, affordable, and online cover for serious illnesses. Build the cover that suits you best by selecting the benefits & the sum insured, get approval immediately, and pay online.",
        "coverageDetailHeader": "Select Critical Illness Insurance Coverage at Roojai ",
        "coverageDetailMsg": "Benefit in the form of Lump Sum ",
        "coverageDetailHeader2": "Benefit of Mosquito-Borne Insurance ",
        "coverageDetailMsg2": "In the form of daily medical expense in cashless to Roojai hospital network ",
        "headerButton": "Get Quote",
        "section01":{
            "header": "Why do I need to get Critical Illness insurance?",
            "item01": "Protection from the high cost of critical illness treatment.",
            "item02": "This insurance can serve as a top up of your health insurance/BPJS program. If you are diagnosed with cancer, cardiovascular disease, neurological illness or kidney failure, you can submit the claim even if your hospital costs are covered by other insurance.",
            "item03": "Cash lump sum can help financially should you to take time off work due to critical illness treatment.",
            "item04": "New treatment methods are often not covered by traditional health insurance, this product gives you extra peace of mind should the worse happen. "
        },
        "section02":{
            "header": "What are the benefits of critical illness insurance? ",
            "item01": "You can select one or more among our five coverages: cancer, mosquito-borne diseases, neurological illnesses, heart diseases, kidney failure. ",
            "item02": "Build your own coverage (benefits and sum insured) based on your needs and budget. ",
            "item03": "You can get cashless treatment in over 2.000 Roojai network hospitals for mosquito borne disease.",
            "item04": "Our cover is designed to offer high value for money and remain affordable for everyone. ",
            "item05": "",
            "item06": ""
        },
        "tbl01":{
            "th-col01": "Category",
            "th-col02": "Plan A (IDR) ",
            "th-col03": "Plan B (IDR) ",
            "th-col04": "Plan C (IDR) ",
            "th-col05": "Plan D (IDR) ",
            "td-row01": "Cancer",
            "td-row02": "Cardiovascular disease",
            "td-row03": "Neurological diseases",
            "td-row04": "Kidney failure"
        },
        "tbl02":{
            "th-col01": "In-Patient Expense",
            "th-col02": "Plan A (IDR) ",
            "th-col03": "Plan B (IDR) ",
            "th-col04": "Plan C (IDR) ",
            "th-col05": "Plan D (IDR) ",
            "td-row01": "Room and medical costs* ",
            "td-row02": "Overall annual limit ** ",
            "note1" : "* Daily cash per day, a total of 30 days in 1 year ",
            "note2" : "** Maximum 2x hospitalization "
        }
    },
    "thb": "THB"
  },
  "id": {
    "headerCaption": {
        "firstline": "Dapatkan penawaran dalam beberapa klik",
        "secondline": "",
        "roojai" : "Roojai.co.id",
        "online": "online",
        "insurance": "insurance"
    },
    "headerDesc": "Asuransi online yang mudah, terjangkau, dan dapat diandalkan",
    "motorCar":  "Mobil",
    "heartdiseases": "Penyakit Jantung ",
    "motorBike": "Penyakit Akibat Gigitan Nyamuk",
    "cancerInsurance": "Kanker",
    "ciHtml":"{0}{1}",
    "ciInsurance": {
        "line1": "Penyakit",
        "line2": "Kritis"
    },
    "pa": "Kecelakaan Diri",
    "covidInbountTravel": "Go Thai Inbound Travel insurance",
    "motorCarCoBranding": "เช็คราคาประกันรถยนต์",
    "motorBikeCoBranding": "เช็คราคาประกันจักรยานยนต์",
    "ewCoBranding": "เช็คราคาประกันอะไหล่รถยนต์",
    "cancerCoBranding": "เช็คราคาประกันมะเร็ง",
    "paCoBranding": "เช็คราคาประกันอุบัติเหตุส่วนบุคคล",
    "ciCoBranding": "เช็คราคาประกันโรคร้ายแรง",
    "paHtml":"{0}{1}",
    "paInsurance":"Asuransi",
    "paPersonal":"Kecelakaan Diri",
    "camera": {
        "line1": "Harga Spesial untuk ",
        "line2": "Pembayaran Premi Tahunan "
    },
    "mobileApp": {
        "line1": "Klaim Asuransi yang Mudah dan Cepat",
        "line2" : ""
    },
    "service": {
        "line1": "Layanan Pembayaran Cashless",
        "line2" : ""
    },
     "guarantee": {
        "line1": "2000+ Jaringan Rumah Sakit",
        "line2" : ""
    },
     "warranty": {
        "line1": "Pembayaran Cicilan dengan ",
        "line2" : "Kartu Kredit dan Kartu Debit "
    },
    "moneyBack": {
        "line1": "Inspeksi Mobil via Video Call ",
        "line2" : ""
    },
    "rsa": {
        "line1": "700+ Bengkel Rekanan Terpercaya",
        "line2" : ""
    },
    "car": {
        "header01": "Asuransi Mobil All Risk di Indonesia",
        "header02": "Roojai menawarkan asuransi mobil all risk dengan premi mulai Rp225rb/bulan. Lindungi mobil kesayanganmu dari risiko kerusakan hingga kehilangan di mana pun dan kapan pun. Atur manfaat proteksi sesuai kebutuhan dan budgetmu dengan mudah secara online hanya dalam 5 menit.",
        "headerButton": "Cek Harga",
        "card01": {
            "h2": "Harga Spesial untuk Pembayaran Premi Tahunan",
            "p": "Bukan sekedar memberikan produk asuransi yang bagus, kamu juga bisa mendapatkan diskon 13% untuk setiap pembelian produk asuransi kesehatan di Roojai dengan memilih pembayaran premi secara tahunan yang pastinya bikin beli asuransi jadi lebih hemat dan terjangkau.",
            "p-car" : "Bukan sekedar memberikan produk asuransi yang bagus, kamu juga bisa mendapatkan diskon 6% untuk pembelian produk asuransi kendaraan di Roojai dengan memilih pembayaran premi secara tahunan yang pastinya bikin beli asuransi jadi lebih hemat dan terjangkau."
        },
        "card02": {
            "h2": "Klaim Asuransi yang Mudah dan Cepat",
            "p": "Prosedur klaim asuransi cashless ataupun reimbursement bisa dilakukan dengan yang cepat dan mudah serta paperless. Tim klaim kami akan membantumu melakukan prosedur pengajuan klaim asuransi hingga selesai. Ketahui juga status klaimmu melalui pesan Whatsapp yang kami kirimkan. Proses klaim yang mudah, melengkapi perwujudan fungsi asuransi yang sesungguhnya."
        },
        "card03": {
            "h2": "Layanan Pembayaran Cashless",
            "p": "Tagihan biaya rawat inap bisa dibayarkan secara cashless di rumah sakit yang terdaftar di website Roojai. Kamu jadi tidak perlu menunggu lama untuk lakukan pembayaran asuransi dan bisa langsung check out dari rumah sakit setelah perawatan selesai."
        },
        "card04": {
            "h2": "2000+ Jaringan Rumah Sakit",
            "p": "Dengan lebih dari 2.000 jaringan rumah sakit yang dimiliki Roojai di seluruh Indonesia, kamu bisa dengan mudah mencari layanan kesehatan terdekat dan terpercaya di wilayah terdekatmu. Pastikan kamu cek rumah sakit terdekat sebelum dapatkan perawatan",
        },
        "card05": {
            "h2": "Pembayaran Cicilan dengan Kartu Kredit dan Kartu Debit",
            "p": "Kelebihan asuransi Roojai lainnya adalah cara bayar asuransi yang kamu pilih. Kamu bisa bayar asuransi secara cicilan dengan kartu kredit dan kartu debit,sehingga dengan cara pembayaran yang fleksibel, membayar premi asuransi bisa disesuaikan dengan kemampuan finansialmu. "
        },
        "card06": {
            "h2": "Emergency Roadside Assistance",
            "p": "Layanan yang tersedia 24 jam setiap hari ini menjadi keuntungan asuransi mobil Roojai, memudahkanmu mendapatkan bantuan di jalan ketika mobilmu mengalami masalah seperti mogok, ban kempes, kunci tertinggal di dalam mobil, dan lainnya."
        },
        "card07": {
            "h2": "Inspeksi Mobil via Video Call",
            "p": "Inspeksi mobil secara online melalui video call merupakan keuntungan asuransi mobil Roojai yang memungkinkan pengecekan kendaraanmu dilakukan lebih cepat dan lebih nyaman karena bisa dilakukan di mana saja.",
        },
        "card08": {
            "h2": "700+ Bengkel Rekanan Terpercaya",
            "p": "Temukan bengkel rekanan Roojai atau bengkel dealer resmi terdekat di wilayahmu, untuk mendapatkan layanan terbaik saat perbaikan mobil kesayanganmu.",
        }
    },
    "hd": {
        "header01": "Asuransi Penyakit Jantung Online dari Roojai",
        "header02": "Pilih manfaat sesuai dengan kebutuhan dan bayar secara online dengan cicilan atau bayar tahunan. Dapatkan uang santunan hingga Rp 1 miliar, premi bisa diatur dengan menggunakan 'plan selection tool' kami.",
        "headerButton": "Cek Harga",
        "section01": {
            "header01": "Mengapa Memiliki Asuransi Penyakit Jantung itu Penting?",
            "subHeader": "Ada beberapa keuntungan untuk memiliki asuransi ini, seperti:",
            "p01": "Sebagai proteksi dari resiko finansial akibat penyakit jantung",
            "p02": "Sebagai pelengkap asuransi kesehatanmu yang lain/BPJS, jika kamu di diagnosa penyakit stroke Iskemik atau Hemoragik, Serangan Jantung Akut, dan Penyakit Arteri Koroner.",
            "p03": "Santunan tunai sekaligus dapat berfungsi sebagai pengganti biaya yang hilang untukmu atau keluarga akibat masa perawatan penyakit jantung.",
            "p04": "Beberapa metode baru dalam dunia medis biasanya tidak ditanggung oleh asuransi kesehatan pada umumnya. Dengan memiliki asuransi ini, kamu bisa bebas khawatir dari biaya perawatan yang tidak ditanggung oleh asuransi kesehatan umum.",
        }
    },
    "mb": {
        "header01": "Asuransi Penyakit Akibat Gigitan Nyamuk di Indonesia",
        "header02": "Proteksi untuk dirimu dan keluarga dari resiko kesehatan yang ada di daerah beriklim tropis (penyakit tropis), yang disebabkan oleh gigitan nyamuk. Dapatkan biaya perawatan untuk demam berdarah, malaria, chikungunya, zika dan demam kuning secara cashless di 2.000 rumah sakit rekanan Roojai.",
        "headerButton": "Cek Harga Premi",
        "body_h3": "ความคุ้มครองหลักของประกันภัยมอเตอร์ไซค์ภาคสมัครใจ ชั้น 1",
        "section01": {
            "h4": "Kenapa memiliki asuransi penyakit akibat gigitan nyamuk itu penting?",
            "p01": "Hidup di Indonesia yang termasuk negara tropis membuat kamu rentan terkena penyakit akibat gigitan nyamuk (demam berdarah, malaria, chikungunya, zika, dan demam kuning).",
            "p02": "Penyakit ini dapat memakan biaya perawatan yang tinggi jika terjadi komplikasi. Asuransi ini akan menanggung biaya perawatan kamu selama dirumah sakit, sehingga kamu bisa bebas khawatir dari biaya rumah sakit.",
            "p03": "Kamu bisa mengakses lebih dari 2.000 jaringan rumah sakit terpercaya Roojai untuk mendapatkan perawatan terbaik.",
        },
        "section02": {
            "h301": "Keuntungan eksklusif untuk pelanggan Roojai",
            "h302": "Hanya dengan menunjukkan Roojai Care Card dan KTP/Paspor, kamu bisa langsung mendapatkan fasilitas rawat inap."
        },
        "section03": {
            "h2": "Jaringan Rumah Sakit Roojai",
            "p": "Biaya pengobatan pemegang polis asuransi penyakit akibat gigitan nyamuk dari Roojai akan ditanggung sesuai dengan nilai pertaggungan yang tertera di polis dan berlaku di lebih dari 2.000 rumah sakit jaringan Roojai."
        }
    },
    "pa": {
        "header01": "Asuransi Kecelakaan Diri ",
        "header02": "Dapatkan proteksi menyeluruh dari kecelakaan diri untuk kamu yang aktif dengan beragam pilihan paket yang bisa dipilih sesuai dengan budget dan gaya hidup dalam beberapa menit secara online.",
        "header03": "",
        "headerButton": "Cek Harga ",
        "section01": {
            "h3": "Apa manfaat unik dari asuransi kecelakaan diri?",
            "p": "Asuransi kecelakaan diri adalah jenis asuransi kesehatan yang memberikan kamu proteksi dari risiko kerugian jangka panjang akibat kecelakaan. Asuransi ini memiliki berbagai manfaat unik yang tidak dimiliki asuransi kesehatan biasa, seperti: ",
            "li01": "Kamu bisa mendapatkan santunan tunai sebagai pengganti pemasukan yang hilang akibat kecelakaan, atau mendapatkan biaya perawatan akibat kecelakaan.",
            "li02": "Kamu juga bisa mendapatkan manfaat biaya perawatan rawat inap yang dibayarkan langsung ke rumah sakit (cashless).",
            "li03": "Proteksi untuk kamu yang aktif. Asuransi Kecelakaan Diri akan melindungi kamu dari berbagai aktifitas dan hobby seperti olahraga ekstrim, mengendarai kendaraan roda dua dan lainnya.",
        },
        "section02": {
            "h301": "Keuntungan eksklusif untuk pelanggan Roojai",
            "h302": "Hanya dengan menunjukkan Roojai Care Card dan KTP/Paspor, kamu bisa langsung mendapatkan fasilitas rawat inap."
        },
        "section03": {
            "h2": "Jaringan Rumah Sakit Roojai",
            "p": "Biaya pengobatan pemegang polis asuransi penyakit akibat gigitan nyamuk dari Roojai akan ditanggung sesuai dengan nilai pertaggungan yang tertera di polis dan berlaku di lebih dari 2.000 rumah sakit jaringan Roojai."
        }
	},
    "cancer": {
        "header": "Asuransi Kanker Terbaik dari Roojai",
        "subHeader": "Lindungi dirimu dan keluarga dari risiko akibat penyakit kanker dengan harga terbaik di kelasnya. Pilih sendiri santunan yang kamu butuhkan sesuai budget secara online.",
        "headerButton": "Cek Harga ",
        "section01": {
            "h2": "Mengapa Memiliki Asuransi Kanker itu Penting?",        		
            "p01": "Sebagai proteksi dari resiko finansial akibat kanker.",
            "p02": "Sebagai pelengkap asuransi kesehatanmu yang lain/BPJS. Jika kamu di diagnosa penyakit kanker invasif stadium awal dan stadium lanjut.",
            "p03": "Santunan tunai secara langsung yang dapat berfungsi sebagai pengganti biaya yang hilang untukmu atau keluarga akibat masa perawatan kanker.",
            "p04": "Beberapa metode baru dalam dunia medis biasanya tidak ditanggung oleh asuransi kesehatan pada umumnya. Dengan memiliki asuransi ini, kamu bisa bebas khawatir dari biaya perawatan yang tidak ditanggung oleh asuransi kesehatan umum."
        },
        "section02": {
            "h3": "Kamu bisa memperpanjang proteksi atas penyakit yang tertera pada polis jika kamu melakukan pembayaran:",
            "col01": "Saat tenggat waktu perpanjangan polis ",
            "col02": "Sebelum tenggat waktu perpanjangan polis",
            "col03": "Atau dalam masa tenggang (30 hari)"
        },
        "section03": {
            "h4": "Apa yang membuat asuransi kanker dari Roojai berbeda?",
            "lo01": "Mudah. Asuransi kanker dari Roojai telah dirancang khusus dengan meminimalisir pengecualian dalam polis.",
            "lo02": "Atur proteksi kamu. Kamu bisa pilih sendiri santunan sesuai dengan kebutuhan dan budget kamu.",
            "lo03": "Fleksibel. Kamu juga bisa menambahkan proteksi lainnya dengan cek Asuransi Penyakit Kritis Roojai dan pilih proteksi lainnya seperti proteksi untuk Penyakit Jantung, Penyakit Saraf, Penyakit akibat gigitan nyamuk dan Penyakit Gagal Ginjal."
        },
    },
    "ci": {
        "header": "Asuransi Penyakit Kritis Online dari Roojai",
        "subHeader": "",
        "headerDesc": "Asuransi Penyakit Kritis online yang terjangkau dan fleksibel. Atur proteksi dan beli sesuai yang kamu inginkan dengan santunan tunai yang kamu butuhkan secara online! ",
        "coverageDetailHeader": "Pilihan Manfaat Asuransi Penyakit Kritis Roojai ",
        "coverageDetailMsg": "Manfaat Berupa Uang Santunan ",
        "coverageDetailHeader2": "Manfaat Asuransi Penyakit Kibat Gigitan Nyamuk ",
        "coverageDetailMsg2": "Berupa penggantian biaya rawat inap harian secara cashless pada jaringan rumah sakit Roojai ",
        "headerButton": "Cek harga",
        "section01":{
            "header": "Mengapa memiliki asuransi Penyakit Kritis itu penting?",
            "item01": "Sebagai perlindungan dari beban keuangan akibat mahalnya biaya penyakit kritis.",
            "item02": "Sebagai pelengkap asuransi kesehatanmu yang lain/BPJS. Jika kamu di diagnosa penyakit kanker, jantung, saraf dan gagal ginjal, kamu tetap bisa mengajukan klaim meskipun biaya perawatanmu sudah ditanggung asuransi lain.",
            "item03": "Sebagai pengganti biaya yang hilang untukmu atau keluarga akibat masa perawatan penyakit kritis.",
            "item04": "Beberapa metode baru dalam dunia medis biasanya tidak ditanggung oleh asuransi kesehatan pada umumnya. Dengan memiliki asuransi Penyakit Kritis, kamu bisa bebas khawatir dari biaya perawatan yang tidak ditanggung oleh asuransi kesehatan umum. "
        },
        "section02":{
            "header": "Apa saja keuntungan memiliki asuransi penyakit kritis Roojai? ",
            "item01": "Kamu bisa pilih satu atau lebih proteksi yang kamu inginkan dari 5 kategori, seperti: Proteksi untuk kanker, penyakit akibat nyamuk, penyakit syaraf, jantung, dan gagal ginjal.",
            "item02": "Atur sendiri proteksi yang kamu inginkan (manfaat dan santunan tunai) sesuai dengan kebutuhan dan budget.",
            "item03": "Kamu bisa mendapatkan biaya pengobatan secara cashless di lebih dari 2000 rumah sakit rekanan Roojai untuk penyakit yang disebabkan oleh nyamuk. ",
            "item04": "Kami memiliki produk yang terjangkau dengan harga terbaik di kelasnya yang dirancang khusus untuk kamu. ",
            "item05": "",
            "item06": ""
        },
        "tbl01":{
            "th-col01": "Kategori",
            "th-col02": "Plan A (Rp) ",
            "th-col03": "Plan B (Rp) ",
            "th-col04": "Plan C (Rp) ",
            "th-col05": "Plan D (Rp) ",
            "td-row01": "Penyakit Kanker",
            "td-row02": "Penyakit Jantung",
            "td-row03": "Penyakit Gangguan Syaraf",
            "td-row04": "Gagal Ginjal "
        },
        "tbl02":{
            "th-col01": "Penggantian Biaya Rumah Sakit ",
            "th-col02": "Plan A (Rp) ",
            "th-col03": "Plan B (Rp) ",
            "th-col04": "Plan C (Rp) ",
            "th-col05": "Plan D (Rp) ",
            "td-row01": "Biaya kamar dan perawatan* ",
            "td-row02": "Batas limit tahunan keseluruhan ** ",
            "note1" : "* Santunan harian per hari, total 30 hari dalam 1 tahun ",
            "note2" : "** Maksimal 2 x rawat inap "
        }
    },
    "thb": "บาท"
  }
}
</i18n>